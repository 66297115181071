* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html {
  height: 100%;
}

input {
  outline: none;
  background: white;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {

  -webkit-text-fill-color: black;
  -webkit-box-shadow: 0 0 0px 1000px white inset;
  transition: background-color 5000s ease-in-out 0s;
}

@-webkit-keyframes autofill {
  to {
      color: #666;
      background: transparent;
  }
}

input:-webkit-autofill {
  -webkit-animation-name: autofill;
  -webkit-animation-fill-mode: both;
}

button {
  outline: none;
}

.cursorPointer {
  cursor: pointer;
}

.clearBorder {
  border: 0px;
}

/* Colors */
.blackText {
  color: black;
}

.brightRed {
  background: #c21f40;
}

.silver {
  background: silver;
}

.fullHeight {
  height: 100%;
  max-height: 100%;
}

.redText {
  color: #c21f40;
}

.whiteBackground {
  background: white;
}
/* Styles for the next and previous pokemon */

.prev:hover {
  opacity: 0.5;
}

.next:hover {
  opacity: 0.5;
}

.floatRight {
  float: right;
}

.flex1line {
  text-align: center;
  display: flex;
  justify-content: center;
}

.whiteText {
  color: white;
}

body {
  font-family: "Roboto", sans-serif;
  font-size: 1rem;
  line-height: 1.6;
  color: black;
  background: whitesmoke;
  height: 100%;
}

.nav {
  display: flex;
  width: 100%;
  justify-content: space-between;
  background: #ed254e;
  color: black;
  align-items: center;
  /*This makes sure the items on the navbar are completely aligned*/
}

.pd-1 {
  padding: 1em;
}

.top-mg-2 {
  margin-top: 5em;
}

.mr-Sides-1 {
  margin: 0 1em;
}

.alignRight {
  text-align: right;
}

.centers {
  display: flex;
  justify-content: center;
  align-items: center;
}

.spaceBetween {
  display: flex;
  justify-content: space-between;
}

.centerText {
  text-align: center;
}

a {
  text-decoration: none;
  color: black;
}

.nav ul {
  display: flex;
}

.nav a {
  padding: 0.45rem 1rem;
  margin: 0 0.25rem;
}

li {
  list-style-type: none;
}

.container {
  width: 60%;
  margin: auto;
  padding: 2em 2em;
  background: whitesmoke;
  height: 100%;
}

.pkSearch {
}

.shorterWidth {
  width: 65%;
  max-width: 100%;
}

.smallWidth{
  width: 20%;
}

.search {
  width: 100%;
  padding: 1em;
  display: block;
  border: 1px solid #ccc;
  border-right: 0px;
}

.rightBorder {
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  border-right: 1px solid #ccc;
}

.inputTextSize {
  font-size: 20px;
}

.goSubmit {
  width: 5%;
  max-width: 30%;
  font-weight: bold;
}

.hideOutline {
  outline: none;
}

.submitBtn {
  background: #f5f5f5;
}

.randomizer {
  width: 100%;
  background-color: inherit;
  padding: 0.3em;
  margin-top: 2em;
}

/* .randomizer:hover {
  background: #c21f40;
} */

/* .removeContent {
  width: 100%;
  border: 1px solid #ccc;
  background: #f4fffd;
  padding: 0.3em;
  margin-top: 0.5em;
} */

.removeContent:hover {
  background: #f5f5f5;
}

.pad2 {
  padding: 2em;
}

.sidepad2 {
  padding-left: 2em;
  padding-right: 2em;
}

.pokeName {
  text-align: center;
  display: inline-block;
  font-size: 1.5em;
}

.pokeNum {
  margin-right: 1em;
  display: inline-block;
  font-weight: bold;
  font-size: 1.5em;
}

.space-between {
  margin: 0;
}

.pokeType {
  border: 1px solid black;
  padding: 1em;
  border-radius: 5px;
}

.clickSprite {
  font-size: 10px;
}

 .sprites{
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
} 

.evolutions {
  display: flex;
  justify-content: center;
}

.evolutionArrows {
  display: flex;
  justify-content: center;
}

.roundBackground {
  border: 1px solid #011936;
  border-radius: 50%;
  background: #f9dc5c;
}

.alert {
  padding: 0.7rem;
  margin: 1rem 0;
  opacity: 0.9;
  background: var(--light-color);
  color: #333;
}

/* Snackbar/Toast */

/* The snackbar - position it at the bottom and in the middle of the screen */
#snackbar {
  padding: 0.7rem;
  margin: 0.7rem 0;
  opacity: 0.9;
  background: #333;
  color: white;
}

/* Show the snackbar when clicking on a button (class added with JavaScript) */
#snackbar.show {
  visibility: visible;
  /* Show the snackbar */
  /* Add animation: Take 0.5 seconds to fade in and out the snackbar.
  However, delay the fade out process for 2.5 seconds */
  -webkit-animation: fadein 0.5s, fadeout 0.5s 6s;
  animation: fadein 0.5s, fadeout 0.5s 6s;
}

/* These are to fit with the pokemon types and give them a color reminiscent of pokemon */

.normal {
  background: #a8a877;
  border-radius: 5px;
}

.fire {
  background: #f0802f;
  border-radius: 5px;
}

.water {
  background: #6790f0;
  border-radius: 5px;
}

.electric {
  background: #f8d02f;
  border-radius: 5px;
}

.grass {
  background: #78c84f;
  border-radius: 5px;
}

.ghost {
  background: #705897;
  border-radius: 5px;
}

.ice {
  border-radius: 5px;
  background: #98d8d8;
}

.fighting {
  background: #c03028;
  border-radius: 5px;
}

.poison {
  background: #9f409f;
  border-radius: 5px;
}

.ground {
  background: #e0c067;
  border-radius: 5px;
}

.bug {
  background: #a8b820;
  border-radius: 5px;
}

.flying {
  background: #a890f0;
  border-radius: 5px;
}

.psychic {
  background: #f85887;
  border-radius: 5px;
}

.rock {
  background: #b8a037;
  border-radius: 5px;
}

.dragon {
  background: #7038f8;
  border-radius: 5px;
}

.dark {
  background: #6f5847;
  border-radius: 5px;
}

.steel {
  background: #b8b8d0;
  border-radius: 5px;
}

.fairy {
  background: #ee98ac;
  border-radius: 5px;
}

.enlarge {
  width: 2em;
}

/* Animations to fade the snackbar in and out */
@-webkit-keyframes fadein {
  from {
    bottom: 0;
    opacity: 0;
  }

  to {
    bottom: 30px;
    opacity: 1;
  }
}

@keyframes fadein {
  from {
    bottom: 0;
    opacity: 0;
  }

  to {
    bottom: 30px;
    opacity: 1;
  }
}

@-webkit-keyframes fadeout {
  from {
    bottom: 30px;
    opacity: 1;
  }

  to {
    bottom: 0;
    opacity: 0;
  }
}

@keyframes fadeout {
  from {
    bottom: 30px;
    opacity: 1;
  }

  to {
    bottom: 0;
    opacity: 0;
  }
}

/* Making the webpage responsive */

@media (max-width: 550px) {
  .container {
    width: 85%;
  }

  .smallTextMobile {
    font-weight: 200;
  }

  .smallOnMobile {
    width: 5em;
  }

  .goSubmit{
    width: 30%;
    max-width: 100%;
  }

  .hideOnSmall {
    display: none !important;
  }

  .flexDown {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .centerFlex {
    align-content: center;
  }

  .smallFontOnSmallScreen {
    font-size: 15px;
  }
}

@media (min-width: 750px) {
  .container {
    width: 80%;
  }
}

@media (max-width: 1300px) {
  .hideOnSmall {
    display: none !important;
  }

  .flexDown {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

@media (min-width: 1300px) {
  .hideOnLarge {
    display: none !important;
  }
}
